import React, { useEffect, useMemo } from 'react';
import { SelectItem } from 'andromeda/types/select/common';
import Select from 'andromeda/selects/select';
import { useActiveUseCaseConfig } from './useActiveUseCaseConfig';

interface VendorDropdownProps {
    vendor: string;
    setVendor: (vendor: string) => void;
}

export const VendorDropdown: React.FC<VendorDropdownProps> = ({ vendor, setVendor }) => {
    const { data } = useActiveUseCaseConfig();

    const defaultRecsContexts = useMemo(
        () =>
            data?.personalization_contexts?.filter(item => item.title.startsWith('Default Recs')) ??
            [],
        [data?.personalization_contexts],
    );

    const vendorOptions: SelectItem[] = defaultRecsContexts.map(item => ({
        label: item.title.replace('Default Recs ', ''),
        value: item.title,
    }));

    useEffect(() => {
        if (vendor || !vendorOptions[0]?.value) return;
        setVendor(vendorOptions[0]?.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultRecsContexts]);

    return (
        <Select
            data={vendorOptions}
            value={vendor}
            onChange={setVendor}
            width={250}
            cleanDesign
            label="Vendor:"
        />
    );
};
