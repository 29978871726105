import { SPACEPORT_API_URL } from '../buildConfig/processEnv';
import useApiQuery from './useApiQuery';

export interface SkuItem {
    product_id: string;
    product_name: string;
    category: string;
    brand: string;
}

export const useSkuList = (namespace: string) =>
    useApiQuery<SkuItem[]>({
        url: `${SPACEPORT_API_URL}/spaceport/api/personalizer/personalize/namespace/${namespace}/products`,
    });
