import React from 'react';
import { ConfigurationsHeader } from './ConfigurationsHeader';
import { TextForLanguage } from './TextForLanguage';
import { RecommendationsCard } from './RecommendationsCard';
import { TextAndImageForLanguage } from './TextAndImageForLanguage';
import { Triggers } from './Triggers';
import { useConfigurationsQueryParams } from './useConfigurationsQueryParams';
import { DefaultRecsLocalStateProvider } from './RecommendationsCard/useDefaultRecsContext';
import { useSelectedPersonalizationContext } from './useSelectedPersonalizationContext';

const TEXT_FOR_LANGUAGE_USE_CASES = ['QUICK_ORDER', 'FORGOTTEN_ITEMS'];
const TEXT_AND_IMAGE_FOR_LANGUAGE_USE_CASES = ['CROSS_SELL_UP_SELL', 'FEATURED_OFFERS'];
const DEFAULT_RECS_USE_CASES = ['QUICK_ORDER', 'FORGOTTEN_ITEMS', 'CROSS_SELL_UP_SELL'];
const TRIGGERS_USE_CASES = ['CROSS_SELL_UP_SELL'];

const TEXT_FOR_LANG_TITLES: Record<string, string> = {
    QUICK_ORDER: 'Carousel features',
    FORGOTTEN_ITEMS: 'Carousel features',
};

const TEXT_AND_IMAGE_FOR_LANG_TITLES: Record<string, string> = {
    CROSS_SELL_UP_SELL: 'Popup features',
    FEATURED_OFFERS: 'Carousel features',
};

export const Configurations = () => {
    const { selectedUseCase, selectedVendor } = useConfigurationsQueryParams();
    const { selectedContext } = useSelectedPersonalizationContext({
        type: 'defaultRecs',
        vendor: selectedVendor,
    });
    return (
        <>
            <ConfigurationsHeader />
            <div className="grid auto-cols-fr grid-cols-1 gap-4 pb-10 lg:grid-cols-2">
                {TEXT_FOR_LANGUAGE_USE_CASES.includes(selectedUseCase) && (
                    <TextForLanguage title={TEXT_FOR_LANG_TITLES[selectedUseCase]} />
                )}
                {TEXT_AND_IMAGE_FOR_LANGUAGE_USE_CASES.includes(selectedUseCase) && (
                    <TextAndImageForLanguage
                        title={TEXT_AND_IMAGE_FOR_LANG_TITLES[selectedUseCase]}
                    />
                )}
                {DEFAULT_RECS_USE_CASES.includes(selectedUseCase) && (
                    <DefaultRecsLocalStateProvider initialState={selectedContext}>
                        <RecommendationsCard />
                    </DefaultRecsLocalStateProvider>
                )}
                {TRIGGERS_USE_CASES.includes(selectedUseCase) && <Triggers />}
            </div>
        </>
    );
};
