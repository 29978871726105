import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ExclamationMark } from 'tabler-icons-react';
import { Check } from 'phosphor-react';
import { Personalization, UseCaseConfig } from '../../types/Personalization';
import { useAppContext } from '../../contexts/appContext';
import { SPACEPORT_API_URL } from '../../buildConfig/processEnv';
import { getUseCaseConfigUrl } from './useActiveUseCaseConfig';
import { useConfigurationsQueryParams } from './useConfigurationsQueryParams';
import { notification } from '../../utils/notification';
import colors from '../../constants/colors';

export interface ContextImage {
    key: string;
    value: string;
}

export interface TranslationContext {
    language: string;
    default: boolean;
    description: string;
    title: string;
    images: ContextImage[];
}

export interface TriggersContext {
    event: string;
    key: string;
    value: number;
}

export type MixedPersonalizationContext =
    | Partial<Personalization>[]
    | TranslationContext
    | TriggersContext;

export interface MutateContextParams {
    id: string;
    context: MixedPersonalizationContext;
}

export const useUpdateContext = () => {
    const { user } = useAppContext();
    const queryClient = useQueryClient();

    const { selectedNamespace, selectedUseCase } = useConfigurationsQueryParams();

    return useMutation<UseCaseConfig, Error, MutateContextParams>({
        mutationFn: async ({ id, context }) => {
            const useCaseConfigKey = [getUseCaseConfigUrl(selectedUseCase, selectedNamespace)];

            queryClient.cancelQueries({ queryKey: useCaseConfigKey });

            queryClient.setQueryData(useCaseConfigKey, (oldData: UseCaseConfig) => {
                if (!oldData) return oldData;

                return {
                    ...oldData,
                    personalization_contexts: oldData.personalization_contexts.map(item => {
                        if (item.id === id) {
                            return {
                                ...item,
                                context: JSON.stringify(context),
                            };
                        }
                        return item;
                    }),
                };
            });

            const url = `${SPACEPORT_API_URL}/spaceport/api/configurator/use_case/${selectedUseCase}/namespace/${selectedNamespace}/personalization_context/update`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${user.siberiaToken}`,
                },
                body: JSON.stringify({
                    id,
                    context: JSON.stringify(context),
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update context');
            }

            queryClient.invalidateQueries({ queryKey: useCaseConfigKey });

            return response.json();
        },
        onError: error => {
            notification({
                message: error.message,
                icon: <ExclamationMark />,
                color: colors.red,
            });
        },
        onSuccess: () => {
            notification({
                message: 'Context updated',
                color: colors.darkGreen,
                icon: <Check />,
            });
        },
    });
};
