import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ExclamationMark } from 'tabler-icons-react';
import { Check } from 'phosphor-react';
import { UseCaseConfig } from '../../types/Personalization';
import { useAppContext } from '../../contexts/appContext';
import { SPACEPORT_API_URL } from '../../buildConfig/processEnv';
import { useConfigurationsQueryParams } from './useConfigurationsQueryParams';
import { notification } from '../../utils/notification';
import colors from '../../constants/colors';
import { useActiveUseCaseConfig } from './useActiveUseCaseConfig';

export interface MutateContextParams {
    file: File;
}

export const useUpdateFileContext = ({ onSuccess }: { onSuccess: () => void }) => {
    const { user } = useAppContext();

    const { selectedNamespace, selectedUseCase } = useConfigurationsQueryParams();
    const { refetch } = useActiveUseCaseConfig();

    return useMutation<UseCaseConfig, Error, MutateContextParams>({
        mutationFn: async ({ file }) => {
            const url = `${SPACEPORT_API_URL}/spaceport/api/configurator/use_case/${selectedUseCase}/namespace/${selectedNamespace}/personalization_context/default_recommendations/bulk`;
            const body = new FormData();
            body.append('file', file);
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${user.siberiaToken}`,
                },
                body,
            });

            if (!response.ok) {
                // Handling HTML Response
                const res = await response
                    .clone()
                    .text()
                    .catch(() => {
                        throw new Error('Failed to parse response');
                    });
                const res2 = await response
                    .clone()
                    .json()
                    .catch(() => {
                        throw new Error('Failed to parse response');
                    });

                return Promise.reject(
                    Object.keys(res2).length > 0
                        ? { ...res2, message: res2?.message ?? res2?.detail }
                        : res,
                );
            }

            return response.json();
        },
        onError: error => {
            notification({
                message: error.message,
                icon: <ExclamationMark />,
                color: colors.red,
            });
        },
        onSuccess: () => {
            onSuccess();
            refetch();
            notification({
                message: 'Context updated',
                color: colors.darkGreen,
                icon: <Check />,
            });
        },
    });
};
