import React, { useEffect, useState } from 'react';
import { Divider, Input } from '@mantine/core';
import cx from 'classnames';
import { Card } from 'andromeda/Card';
import { Text } from 'andromeda';
import { DateFormatter } from '@/utils/DateFormatter';
import { TriggersContext, useUpdateContext } from './useUpdateContext';
import { useSelectedPersonalizationContext } from './useSelectedPersonalizationContext';
import { Button } from '../../andromeda/Button';

interface TriggerProps {
    title: string;
    description: string;
    numberOfUniqueSKUs: number;
    updateNumberOfUniqueSKUs: (value: number) => void;
    className?: string;
    selectedContext: {
        context: TriggersContext;
        id: string;
        title: string;
        personalization_type: string;
        geo_level: any;
        geo_level_value: any;
        product_level: any;
        product_level_value: any;
        last_updated_by: string;
        last_updated_at: string;
    };
}

const Trigger: React.FC<TriggerProps> = ({
    title,
    description,
    numberOfUniqueSKUs,
    updateNumberOfUniqueSKUs,
    className,
    selectedContext,
}) => (
    <div className={cx('space-y-4', className)}>
        <h5 className="font-medium">{title}</h5>
        <p className="text-sm">{description}</p>
        {(selectedContext?.last_updated_by || selectedContext?.last_updated_at) && (
            <Text type="12Reg">
                Last updated{' '}
                {selectedContext?.last_updated_by && `by ${selectedContext?.last_updated_by}`}
                {selectedContext?.last_updated_at &&
                    ` on ${DateFormatter.formatDate(selectedContext?.last_updated_at)}`}
            </Text>
        )}
        <div className="flex items-center justify-between gap-1">
            <span className="text-sm">Number of unique SKUS</span>
            <Input
                type="number"
                className="w-28"
                value={numberOfUniqueSKUs ?? ''}
                onChange={e => updateNumberOfUniqueSKUs(Number(e.target.value))}
            />
        </div>
    </div>
);

export const Triggers: React.FC = () => {
    // These both selected contexts have the same loading and error values
    const {
        selectedContext: selectedCardViewedContext,
        isLoading,
        error,
    } = useSelectedPersonalizationContext({
        type: 'triggers',
        trigger: 'Cart Viewed',
    });
    const { selectedContext: selectedProductAddedContext } = useSelectedPersonalizationContext({
        type: 'triggers',
        trigger: 'Product Added',
    });

    const initialProductAddedTriggerValue = selectedProductAddedContext?.context.value;
    const initialCartViewedTriggerValue = selectedCardViewedContext?.context.value;

    const [productAddedTriggerValue, setProductAddedTriggerValue] = useState(
        initialProductAddedTriggerValue,
    );
    const [cartViewedTriggerValue, setCartViewedTriggerValue] = useState(
        initialCartViewedTriggerValue,
    );

    useEffect(() => {
        setProductAddedTriggerValue(initialProductAddedTriggerValue);
        setCartViewedTriggerValue(initialCartViewedTriggerValue);
    }, [initialProductAddedTriggerValue, initialCartViewedTriggerValue]);

    const { mutate: mutateProductAdded, isPending: isLoadingProductAddedUpdate } =
        useUpdateContext();
    const { mutate: mutateCartViewed, isPending: isLoadingCartViewedUpdate } = useUpdateContext();

    const isLoadingUpdate = isLoadingProductAddedUpdate || isLoadingCartViewedUpdate;

    const handleSave = () => {
        if (!selectedProductAddedContext || !selectedCardViewedContext) return;
        if (productAddedTriggerValue !== selectedProductAddedContext?.context?.value)
            mutateProductAdded({
                id: selectedProductAddedContext?.id,
                context: {
                    ...selectedProductAddedContext?.context,
                    value: productAddedTriggerValue,
                },
            });
        if (cartViewedTriggerValue !== selectedCardViewedContext?.context?.value)
            mutateCartViewed({
                id: selectedCardViewedContext?.id,
                context: {
                    ...selectedCardViewedContext?.context,
                    value: cartViewedTriggerValue,
                },
            });
    };

    return (
        <Card
            className="flex flex-col"
            innerClassName="grow flex flex-col justify-between gap-2"
            title="Triggers"
            loading={isLoading}
            error={error?.message}
        >
            <div>
                <Trigger
                    title="Product Added Pop-up Trigger"
                    description="A customer adds a product to their cart and there are X unique SKUs in the cart."
                    numberOfUniqueSKUs={productAddedTriggerValue}
                    updateNumberOfUniqueSKUs={setProductAddedTriggerValue}
                    selectedContext={selectedProductAddedContext}
                />
                <Divider className="my-8 border-gray-600" />
                <Trigger
                    title="Cart Viewed Pop-up Trigger "
                    description="A customer views their cart and it contains X unique SKUs."
                    numberOfUniqueSKUs={cartViewedTriggerValue}
                    updateNumberOfUniqueSKUs={setCartViewedTriggerValue}
                    selectedContext={selectedCardViewedContext}
                />
            </div>
            <Button
                containerClassName="mt-auto ml-auto"
                onClick={handleSave}
                loading={isLoadingUpdate}
            >
                Save changes
            </Button>
        </Card>
    );
};
