/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import { Personalization } from '../../types/Personalization';
import { useActiveUseCaseConfig } from './useActiveUseCaseConfig';
import { TranslationContext, TriggersContext } from './useUpdateContext';

interface DefaultRecsContextParam {
    type: 'defaultRecs';
    vendor: string;
}

interface TriggersContextParam {
    type: 'triggers';
    trigger: 'Product Added' | 'Cart Viewed';
}

interface TranslationsContextParam {
    type: 'translations';
    language: string;
}

type UseSelectedPersonalizationContextParams =
    | DefaultRecsContextParam
    | TriggersContextParam
    | TranslationsContextParam;

type ParsedContext<T> = T extends TranslationsContextParam
    ? TranslationContext
    : T extends TriggersContextParam
      ? TriggersContext
      : T extends DefaultRecsContextParam
        ? Personalization[]
        : never;

export const useSelectedPersonalizationContext = <
    T extends UseSelectedPersonalizationContextParams,
>(
    params: T,
) => {
    const queryResponse = useActiveUseCaseConfig();

    const selectedContext = useMemo(() => {
        let title = '';

        switch (params.type) {
            case 'defaultRecs':
                title = params.vendor;
                break;

            case 'triggers':
                title = `Triggers ${params.trigger}`;
                break;

            case 'translations':
                title = 'Translations';
                break;

            default:
                break;
        }

        if (!queryResponse.data?.personalization_contexts) return undefined;

        if (params.type === 'translations') {
            const parsedContext = queryResponse.data?.personalization_contexts
                ?.map(item => ({
                    ...item,
                    context: JSON.parse(item?.context ?? '{}') as ParsedContext<T>,
                }))
                ?.find(item => (item.context as any).language === params.language);

            return parsedContext;
        }

        const rawContext = queryResponse.data?.personalization_contexts?.find(
            item => item.title === title,
        );

        const parsedContext = {
            ...rawContext,
            context: JSON.parse(rawContext?.context ?? '[]') as ParsedContext<T>,
        };

        return parsedContext;
    }, [
        queryResponse.data,
        params.type,
        (params as any).vendor,
        (params as any).trigger,
        (params as any).language,
    ]);

    return {
        selectedContext,
        ...queryResponse,
    };
};
