import ResponsiveLayout from '@/components/responsive/ResponsiveLayout';
import { CONFIGURATIONS_USER_GROUP_WHITELIST } from '@/components/navBar/constants';
import { UserGuard } from '@/components/utils/UserGuard';
import { Configurations } from '../components/configurationsV2/Configurations';

export default function ConfigurationPage() {
    return (
        <ResponsiveLayout title="Configurations">
            <UserGuard userGroupWhitelist={CONFIGURATIONS_USER_GROUP_WHITELIST}>
                <Configurations />
            </UserGuard>
        </ResponsiveLayout>
    );
}
