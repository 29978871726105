/* eslint-disable @next/next/no-img-element */
import React, { PropsWithChildren, useEffect, useState } from 'react';
import _ from 'lodash';
import { Input } from '@mantine/core';
import { Card } from 'andromeda/Card';
import { DateFormatter } from '@/utils/DateFormatter';
import { Text } from 'andromeda';
import { TextForLanguageContent } from './TextForLanguageContent';
import { LanguageDropdown } from './LanguageDropdown';
import ErrorText from '../utils/errorText';
import { ContextImage, useUpdateContext } from './useUpdateContext';
import { useSelectedPersonalizationContext } from './useSelectedPersonalizationContext';
import { Button } from '../../andromeda/Button';

const SectionTitle: React.FC<PropsWithChildren> = ({ children }) => (
    <h5 className="text-xs font-semibold uppercase text-navy-solid-70">{children}</h5>
);

interface ImageSectionProps {
    platform: 'WEB' | 'APP';
    image?: string;
    updateImage: (image: string) => void;
}

export const ImageSection: React.FC<ImageSectionProps> = ({ platform, image, updateImage }) => (
    <div>
        <SectionTitle>Image-{_.startCase(platform)}</SectionTitle>
        <Input
            className="mt-2 w-full"
            classNames={{ input: 'text-xs' }}
            value={image}
            onChange={e => updateImage(e.target.value)}
        />
        {image && (
            <img src={image} alt="popup_image" className="mt-3 h-36 rounded-lg object-contain" />
        )}
        {!image && <ErrorText error={new Error('No image found.')} />}
    </div>
);

interface TextAndImageForLanguageProps {
    title: string;
}

export const TextAndImageForLanguage: React.FC<TextAndImageForLanguageProps> = ({
    title: cardTitle,
}) => {
    const [selectedLanguage, setSelectedLanguage] = useState<string>();

    const { selectedContext, isLoading, error, data } = useSelectedPersonalizationContext({
        type: 'translations',
        language: selectedLanguage,
    });

    const [title, setTitle] = useState(selectedContext?.context?.title);
    const [description, setDescription] = useState(selectedContext?.context?.description);

    const initialWebImage = selectedContext?.context?.images?.find(
        (image: ContextImage) => image.key === 'WEB',
    )?.value;
    const initialAppImage = selectedContext?.context?.images?.find(
        (image: ContextImage) => image.key === 'APP',
    )?.value;

    const [webImage, setWebImage] = useState(initialWebImage);
    const [appImage, setAppImage] = useState(initialAppImage);

    useEffect(() => {
        setTitle(selectedContext?.context?.title);
        setDescription(selectedContext?.context?.description);
        setWebImage(initialWebImage);
        setAppImage(initialAppImage);
    }, [
        selectedContext?.context?.title,
        selectedContext?.context?.description,
        initialWebImage,
        initialAppImage,
    ]);

    const { mutate, isPending: isLoadingUpdate } = useUpdateContext();

    const handleSave = () => {
        if (!selectedContext) return;
        mutate({
            id: selectedContext.id,
            context: {
                ...selectedContext.context,
                title,
                description,
                images: selectedContext.context.images.map((image: ContextImage) => {
                    if (image.key === 'WEB') {
                        return {
                            ...image,
                            value: webImage,
                        };
                    }
                    if (image.key === 'APP') {
                        return {
                            ...image,
                            value: appImage,
                        };
                    }
                    return image;
                }),
            },
        });
    };

    return (
        <Card
            innerClassName="grow flex flex-col gap-2"
            className="flex flex-col 2xl:xl:max-w-5xl"
            title={cardTitle}
            loading={isLoading}
            error={error?.message}
            titleClassName="flex flex-col"
            tooltipElement={
                <div className="w-full">
                    {(selectedContext?.last_updated_by || selectedContext?.last_updated_at) && (
                        <Text type="12Reg">
                            Last updated{' '}
                            {selectedContext?.last_updated_by &&
                                `by ${selectedContext?.last_updated_by}`}
                            {selectedContext?.last_updated_at &&
                                ` on ${DateFormatter.formatDate(selectedContext?.last_updated_at)}`}
                        </Text>
                    )}
                </div>
            }
            actionsElement={
                <LanguageDropdown
                    contexts={data?.personalization_contexts}
                    language={selectedLanguage}
                    onChange={setSelectedLanguage}
                />
            }
        >
            <div className="mb-4 space-y-4">
                <ImageSection platform="WEB" image={webImage} updateImage={setWebImage} />
                <ImageSection platform="APP" image={appImage} updateImage={setAppImage} />
                <TextForLanguageContent
                    title={title}
                    description={description}
                    updateTitle={setTitle}
                    updateDescription={setDescription}
                />
            </div>
            <Button
                containerClassName="mt-auto ml-auto"
                onClick={handleSave}
                loading={isLoadingUpdate}
            >
                Save changes
            </Button>
        </Card>
    );
};
