import React from 'react';
import { rectSortingStrategy } from '@dnd-kit/sortable';
import DraggableDefaultRecsTable from './draggable/draggableDefaultRecsTable';

function DefaultRecsTable({ height }: { height: number }) {
    return (
        <div>
            <DraggableDefaultRecsTable strategy={rectSortingStrategy} height={height} />
        </div>
    );
}

export default DefaultRecsTable;
