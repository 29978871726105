/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Divider, Input, LoadingOverlay, Modal } from '@mantine/core';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import TextAreaDropDown from '@/components/tbd/textAreaDropdown';
import SelectLabelWrapper from 'andromeda/selects/selectLabelWrapper';
import { SelectItem } from 'andromeda/types/select/common';
import { useSkuList } from '../../../hooks/useSkuList';
import { useConfigurationsQueryParams } from '../useConfigurationsQueryParams';
import { Button } from '../../../andromeda/Button';

const formSchema = z.object({
    productID: z.string().min(1, { message: 'Product is required' }),
    quantity: z.coerce.number().int().positive({ message: 'Quantity must required' }),
});

type FormState = z.infer<typeof formSchema>;

interface AddNewSkuModalParams extends FormState {
    product_name?: string;
}

interface AddNewSkuModalProps {
    open?: boolean;
    onClose: () => void;
    onAdd: ({ productID, product_name, quantity }: AddNewSkuModalParams) => void;
}

export const AddNewSkuModal: React.FC<AddNewSkuModalProps> = ({ open, onClose, onAdd }) => {
    const { selectedNamespace } = useConfigurationsQueryParams();

    const { data: skuList, isLoading: isSkuListLoading } = useSkuList(selectedNamespace);

    const skuOptions = useMemo(
        () =>
            Object.values(
                skuList?.reduce(
                    (acc: Record<string, SelectItem>, sku) => ({
                        ...acc,
                        [`${sku.product_id} - ${sku.product_name}`]: {
                            value: sku.product_id,
                            label: `${sku.product_id} - ${sku.product_name}`,
                        },
                    }),
                    {},
                ) ?? [],
            ),
        [skuList],
    );

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
    } = useForm<FormState>({
        defaultValues: {
            productID: '',
            quantity: 1,
        },
        resolver: zodResolver(formSchema),
    });

    const onSubmit = (values: FormState) => {
        const product = skuList?.find(sku => sku.product_id === values.productID);

        onAdd({ ...values, product_name: product?.product_name ?? '' });
        onClose();
        reset();
    };

    return (
        <Modal
            title="Add SKU"
            opened={open}
            onClose={onClose}
            centered
            classNames={{
                content: '!overflow-visible',
            }}
        >
            <form
                className="space-y-2"
                onSubmit={handleSubmit(onSubmit)}
                // ? This to prevent Refresh
                onKeyDown={e => {
                    if (e.code === 'Enter') {
                        e.preventDefault();
                    }
                }}
            >
                <div className="relative z-[99999]">
                    <Controller
                        name="productID"
                        control={control}
                        render={({ field }) => (
                            <SelectLabelWrapper
                                label="Product"
                                textClassName="!text-primary ml-0.5"
                                className="gap-1.5"
                            >
                                <TextAreaDropDown
                                    placeholder="Search for a product"
                                    data={skuOptions}
                                    {...field}
                                    onSubmit={e => field.onChange(e)}
                                    className={{
                                        dropdown: '!bg-[var(--mantine-color-body)]',
                                    }}
                                    noGradient
                                    hideOptionsTitle
                                />
                            </SelectLabelWrapper>
                        )}
                    />
                    <LoadingOverlay
                        className="bottom-0 top-6 w-[410px]"
                        visible={isSkuListLoading}
                        loaderProps={{ variant: 'dots' }}
                    />
                </div>
                <Input.Wrapper
                    label="Quantity"
                    classNames={{ label: 'text-primary ml-0.5 !font-light !text-xs' }}
                    error={errors.quantity?.message}
                >
                    <Input
                        type="number"
                        classNames={{ input: '!text-navy-solid-70 !border-0.5' }}
                        {...register('quantity')}
                    />
                </Input.Wrapper>
                <Divider className="my-4" />
                <div className="flex justify-end">
                    <Button type="submit">Add</Button>
                </div>
            </form>
        </Modal>
    );
};
