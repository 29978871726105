import { Divider, FileInput, Modal } from '@mantine/core';
import React, { useState } from 'react';
import { BareCard, Text } from 'andromeda';
import colors from '@/constants/colors';
import { Info, Warning } from 'phosphor-react';
import classNames from 'classnames';
import { Button } from '../../../andromeda/Button';

interface UploadRecsModelProps {
    open?: boolean;
    onClose: () => void;
    onUpload: (file: File) => void;
    error: Error;
}

export const UploadRecsModel: React.FC<UploadRecsModelProps> = ({
    open,
    onClose,
    onUpload,
    error,
}) => {
    const [file, setFile] = useState<File>();
    const err = file?.size && error?.message;
    return (
        <Modal
            title="Upload Default Recs"
            opened={open}
            onClose={onClose}
            centered
            classNames={{
                content: '!overflow-visible',
            }}
        >
            <div className="flex flex-col items-center justify-center gap-2">
                <BareCard className="w-fit rounded">
                    <Text
                        type="12Reg"
                        style={{ color: err ? colors.yellow : undefined }}
                        className={classNames('flex gap-2 items-center', {
                            '!text-navy-solid-70': !err,
                        })}
                    >
                        {err ? <Warning size={38} /> : <Info size={38} />}
                        {err ||
                            "Note: File must include fields: 'vendor_id', 'product_id', and 'quantity'."}
                    </Text>
                </BareCard>
                <FileInput
                    value={file}
                    onChange={e => setFile(e)}
                    clearable
                    placeholder="Click to Upload default Recs (.csv)"
                    accept="text/csv"
                    className="w-full"
                    error={!!err}
                />
            </div>
            <Divider className="my-4" />
            <div className="flex justify-end">
                <Button onClick={() => onUpload(file)}>Add</Button>
            </div>
        </Modal>
    );
};
