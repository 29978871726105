/* eslint-disable react/no-unused-prop-types */
import React, { useState } from 'react';
import type { DraggableSyntheticListeners } from '@dnd-kit/core';

import { Personalization } from '@/types/Personalization';
import { Table } from '@mantine/core';
import { Trash } from 'phosphor-react';

import classNames from 'classnames';
import { QuantityCell } from '../QuantityCell';
import { useDefaultRecsContext } from '../useDefaultRecsContext';

const headers = {
    '  ': 'index',
    product_id: 'SKU',
    product_name: 'Product',
    quantity: 'quantity',
    ' ': 'Delete',
};
const clickableCell: (keyof typeof headers)[] = [' ', 'quantity'];

export interface Props {
    dragOverlay?: boolean;
    dragging?: boolean;
    handle?: boolean;
    handleProps?: any;
    index?: number;
    listeners?: DraggableSyntheticListeners;
    style?: React.CSSProperties;
    transition?: string | null;
    value: React.ReactNode;
    data: Personalization;
}

export const Item = React.memo(
    React.forwardRef<HTMLTableRowElement, Props>(
        ({ dragOverlay, dragging, handle, listeners, data, ...props }, ref) => {
            const { onDelete, onUpdateQuantity } = useDefaultRecsContext();
            const [isItClickableCells, setIsItClickableCells] = useState(false);

            return (
                <Table.Tr
                    ref={!isItClickableCells ? ref : undefined}
                    {...(!handle || !isItClickableCells ? listeners : undefined)}
                    tabIndex={!handle || !isItClickableCells ? 0 : undefined}
                    className={classNames({
                        'cursor-grab': !dragOverlay || !dragging,
                        'cursor-grabbing': dragOverlay || dragging,
                    })}
                    {...props}
                >
                    {Object.entries(headers).map(([k, x], i) => (
                        <Table.Td
                            key={`Quantity-${data?.product_id}-${i * 1}`}
                            style={{
                                width: k === 'product_name' ? '70%' : '10%',
                            }}
                            onMouseEnter={() =>
                                clickableCell.includes(k as (typeof clickableCell)[0]) &&
                                setIsItClickableCells(true)
                            }
                            onMouseLeave={() =>
                                clickableCell.includes(k as (typeof clickableCell)[0]) &&
                                setIsItClickableCells(false)
                            }
                        >
                            {x === 'index' ? (
                                props?.index ?? 0
                            ) : k === 'quantity' ? (
                                <QuantityCell
                                    value={data?.quantity}
                                    onChange={v => {
                                        onUpdateQuantity(v, data?.product_id);
                                    }}
                                />
                            ) : (
                                data?.[k] ?? (
                                    <button
                                        type="button"
                                        onMouseDown={() => {
                                            if (data?.product_id)
                                                onDelete(data?.product_id, data?.product_name);
                                        }}
                                        aria-label="delete row"
                                    >
                                        <Trash />
                                    </button>
                                )
                            )}
                        </Table.Td>
                    ))}
                </Table.Tr>
            );
        },
    ),
);
