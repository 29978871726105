import React, { useMemo } from 'react';
import cx from 'classnames';
import { InfoCircle } from 'tabler-icons-react';
import { useDisclosure } from '@mantine/hooks';
import { Card } from 'andromeda/Card';
import { DateFormatter } from '@/utils/DateFormatter';
import { Text } from 'andromeda';
import { useConfigurationsQueryParams } from '../useConfigurationsQueryParams';
import { useUpdateContext } from '../useUpdateContext';
import { useUserAccess } from '../../../hooks/useUserAccess';
import { IconWrapperButton } from '../../utils/IconWrapperButton';
import { VendorDropdown } from '../VendorDropdown';
import { useSelectedPersonalizationContext } from '../useSelectedPersonalizationContext';
import { useDefaultRecsContext } from './useDefaultRecsContext';
import { AddNewSkuModal } from './AddNewSkuModal';
import { Button } from '../../../andromeda/Button';
import { useUpdateFileContext } from '../useUpdateFileContext';
import { UploadRecsModel } from './UploadRecsModal';
import DefaultRecsTable from './defaultRecsTable';

export const RecommendationsCard: React.FC = () => {
    const { selectedUseCase, selectedVendor, updateSelectedVendor } =
        useConfigurationsQueryParams();

    const [addSkuModalOpened, { open: openSkuModal, close: closeSkuModal }] = useDisclosure(false);
    const [uploadDefaultRecsOpened, { open: openDefaultRecs, close: closeDefaultRecs }] =
        useDisclosure(false);

    const isNestleUser = useUserAccess({ userGroupWhitelist: ['nestle-spaceport'] });
    const isWilsbachUser = useUserAccess({ userGroupWhitelist: ['spaceport-US-WILSBACH-access'] });
    const isABOneNYUser = useUserAccess({ userGroupWhitelist: ['spaceport-US-ABONE-NY-access'] });
    const isUSDemoUser = useUserAccess({ userGroupWhitelist: ['spaceport-US-DEMO-access'] });

    const { selectedContext, isLoading, error, data } = useSelectedPersonalizationContext({
        type: 'defaultRecs',
        vendor: selectedVendor,
    });

    const { defaultRecs, onAdd, isDisabled: noVendorID } = useDefaultRecsContext();

    const noNewSkus = useMemo(
        () =>
            !(
                // ? Removed SKUs Check
                (
                    (selectedContext?.context ?? []).filter(
                        item => !defaultRecs?.find(itemX => item.product_id === itemX.product_id),
                    ).length > 0 ||
                    // ? Added SKUS Check
                    defaultRecs?.filter(
                        item =>
                            !(selectedContext?.context ?? []).find(
                                itemX => item.product_id === itemX.product_id,
                            ),
                    ).length > 0 ||
                    // ? Order Check
                    defaultRecs?.filter(
                        (item, i) =>
                            (selectedContext?.context ?? []).findIndex(
                                itemX => item.product_id === itemX.product_id,
                            ) !== i,
                    ).length > 0
                )
            ),
        [defaultRecs, selectedContext?.context],
    );

    const { mutate: updateDefaultRecs, isPending: isLoadingUpdate } = useUpdateContext();
    const {
        mutate: updateDefaultRecsFile,
        isPending: isFileUploading,
        error: updateDefaultRecsFileError,
    } = useUpdateFileContext({
        onSuccess() {
            closeDefaultRecs();
        },
    });

    const handleSave = () => {
        if (!selectedContext) {
            return;
        }
        updateDefaultRecs({
            id: selectedContext?.id,
            context: defaultRecs,
        });
    };

    return (
        <Card
            title={
                <>
                    Default recommendations{' '}
                    {(isNestleUser || isWilsbachUser || isABOneNYUser || isUSDemoUser) && (
                        <IconWrapperButton
                            width={450}
                            description="In the absence of a personalized store recommendation, the following SKUs will be recommended. While this is an edge case, new stores will receive default recommendations for the first two weeks after joining BEES."
                        >
                            <InfoCircle />
                        </IconWrapperButton>
                    )}
                </>
            }
            className="flex flex-col"
            innerClassName="flex flex-col grow"
            titleClassName="flex-wrap"
            tooltipElement={
                <div className="w-full">
                    {(selectedContext?.last_updated_by || selectedContext?.last_updated_at) && (
                        <Text type="12Reg">
                            Last updated{' '}
                            {selectedContext?.last_updated_by &&
                                `by ${selectedContext?.last_updated_by}`}
                            {selectedContext?.last_updated_at &&
                                ` on ${DateFormatter.formatDate(selectedContext?.last_updated_at)}`}
                        </Text>
                    )}
                </div>
            }
            loading={isLoading}
            error={error?.message}
            actionsElement={
                <VendorDropdown vendor={selectedVendor} setVendor={updateSelectedVendor} />
            }
        >
            <div
                className={cx('mb-6', {
                    'max-h-[460px]': selectedUseCase !== 'CROSS_SELL_UP_SELL',
                    'max-h-[570px]': selectedUseCase === 'CROSS_SELL_UP_SELL',
                })}
            >
                <DefaultRecsTable height={selectedUseCase !== 'CROSS_SELL_UP_SELL' ? 460 : 570} />
            </div>
            <div className="mt-auto flex items-start justify-end gap-3">
                <Button onClick={openDefaultRecs} disabled={noVendorID || isFileUploading}>
                    Bulk Upload CSV
                </Button>
                <Button onClick={openSkuModal} disabled={noVendorID}>
                    Add SKU
                </Button>
                <Button
                    onClick={handleSave}
                    loading={isLoadingUpdate}
                    disabled={noNewSkus}
                    disabledTooltipLabel="No change was found in SKUs"
                >
                    Save changes
                </Button>
            </div>
            <UploadRecsModel
                open={uploadDefaultRecsOpened}
                onClose={closeDefaultRecs}
                onUpload={f => updateDefaultRecsFile({ file: f })}
                error={updateDefaultRecsFileError}
            />
            <AddNewSkuModal
                open={addSkuModalOpened}
                onClose={closeSkuModal}
                onAdd={obj => onAdd({ ...obj, product_id: obj.productID })}
            />
        </Card>
    );
};
