import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    CollisionDetection,
    defaultDropAnimationSideEffects,
    DndContext,
    DragOverlay,
    DropAnimation,
    getFirstCollision,
    KeyboardSensor,
    MeasuringStrategy,
    MouseSensor,
    pointerWithin,
    rectIntersection,
    TouchSensor,
    UniqueIdentifier,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    SortableContext,
    SortingStrategy,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Personalization } from '@/types/Personalization';
import StyledTable from 'andromeda/styledTable';
import { Table } from '@mantine/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { Item } from './Item';
import { useDefaultRecsContext } from '../useDefaultRecsContext';

const headers = {
    '  ': 'index',
    product_id: 'SKU',
    product_name: 'Product',
    quantity: 'quantity',
    ' ': 'Delete',
};

interface SortableItemProps {
    id: UniqueIdentifier;
    index: number;
    handle: boolean;
    disabled?: boolean;
    itemData: Personalization;
}

function SortableItem({ disabled, id, index, handle, itemData }: SortableItemProps) {
    const { setNodeRef, setActivatorNodeRef, listeners, isDragging, transition } = useSortable({
        id,
    });
    return (
        <Item
            ref={disabled ? undefined : setNodeRef}
            value={id}
            dragging={isDragging}
            handle={handle}
            handleProps={handle ? { ref: setActivatorNodeRef } : undefined}
            index={index}
            transition={transition}
            listeners={listeners}
            data={itemData}
        />
    );
}

const dropAnimation: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
        styles: {
            active: {
                opacity: '0.5',
            },
        },
    }),
};

type Items = UniqueIdentifier[];

interface Props {
    strategy?: SortingStrategy;
    height: number;
}

function DraggableDefaultRecsTable({ strategy = verticalListSortingStrategy, height }: Props) {
    const { defaultRecs, onUpdateTable } = useDefaultRecsContext();
    const [shouldUpdateTable, setShouldUpdateTable] = useState(false);
    const dataObj = defaultRecs.reduce((acc, item) => ({ ...acc, [item.product_id]: item }), {});
    const [items, setItems] = useState<Items>([]);

    useEffect(() => {
        if (!defaultRecs && items.length > 0) return;
        setItems(defaultRecs.map(item => item.product_id));
    }, [defaultRecs, items.length]);

    useEffect(() => {
        if (!shouldUpdateTable) return;
        onUpdateTable(items.reduce((acc, key) => ({ ...acc, [key]: dataObj[key as string] }), {}));
        setShouldUpdateTable(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldUpdateTable]);

    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
    const lastOverId = useRef<UniqueIdentifier | null>(null);

    /**
     * Custom collision detection strategy optimized for multiple containers
     *
     * - First, find any droppable containers intersecting with the pointer.
     * - If there are none, find intersecting containers with the active draggable.
     * - If there are no intersecting containers, return the last matched intersection
     *
     */
    const collisionDetectionStrategy: CollisionDetection = useCallback(args => {
        // Start by finding any intersecting droppable
        const pointerIntersections = pointerWithin(args);
        const intersections =
            pointerIntersections.length > 0
                ? // If there are droppables intersecting with the pointer, return those
                  pointerIntersections
                : rectIntersection(args);
        const overId = getFirstCollision(intersections, 'id');

        if (overId != null) {
            lastOverId.current = overId;

            return [{ id: overId }];
        }

        // If no droppable is matched, return the last match
        return lastOverId.current ? [{ id: lastOverId.current }] : [];
    }, []);
    const [clonedItems, setClonedItems] = useState<Items | null>(null);
    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {}),
    );

    function renderSortableItemDragOverlay(id: UniqueIdentifier) {
        return (
            <StyledTable
                body={[
                    <Item
                        value={id}
                        key={`overlay-${id}`}
                        data={dataObj[id]}
                        dragOverlay
                        style={{ width: '100%' }}
                    />,
                ]}
                width="100%"
                maxHeight="fit-content !important"
                minHeight="fit-content !important"
            />
        );
    }

    const onDragCancel = () => {
        if (clonedItems) {
            // Reset items to their original state in case items have been
            // Dragged across containers
            setItems(clonedItems);
        }

        setActiveId(null);
        setClonedItems(null);
    };

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={collisionDetectionStrategy}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
            measuring={{
                droppable: {
                    strategy: MeasuringStrategy.Always,
                },
            }}
            onDragStart={({ active }) => {
                setActiveId(active.id);
                setClonedItems(items);
            }}
            onDragOver={({ active, over }) => {
                const overId = over?.id;

                if (overId == null || active.id in items) {
                    return;
                }

                setItems(prev => {
                    const overIndex = prev.indexOf(overId);
                    const activeIndex = prev.indexOf(active.id);

                    const activeItem = prev[activeIndex];
                    const overItem = prev[overIndex];

                    const newPrev = [...prev];
                    newPrev[activeIndex] = overItem;
                    newPrev[overIndex] = activeItem;
                    return newPrev;
                });
            }}
            onDragEnd={({ active, over }) => {
                const overId = over?.id;

                setShouldUpdateTable(true);
                if (overId == null) {
                    setActiveId(null);
                    return;
                }

                setActiveId(null);
            }}
            onDragCancel={onDragCancel}
        >
            <div
                style={{
                    boxSizing: 'border-box',
                }}
            >
                <StyledTable
                    headers={Object.values(headers).map(item => (
                        <Table.Th key={item}>{item}</Table.Th>
                    ))}
                    body={
                        <SortableContext items={items} strategy={strategy}>
                            <Table.Tbody>
                                {items.map((id, index) => (
                                    <SortableItem
                                        key={id}
                                        id={id}
                                        index={index}
                                        handle={false}
                                        itemData={dataObj[id]}
                                    />
                                ))}
                            </Table.Tbody>
                        </SortableContext>
                    }
                    maxHeight={height}
                />
            </div>
            <DragOverlay dropAnimation={dropAnimation}>
                {activeId ? renderSortableItemDragOverlay(activeId) : null}
            </DragOverlay>
        </DndContext>
    );
}

export default DraggableDefaultRecsTable;
