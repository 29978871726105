import { CellContext } from '@tanstack/react-table';
import { Input } from '@mantine/core';
import { Personalization } from '../../../types/Personalization';

interface QuantityCellCellProps {
    value: number;
    onChange: (value: number) => void;
}

export const QuantityCell: React.FC<QuantityCellCellProps> = ({ value, onChange }) => (
    <Input
        type="number"
        size="xs"
        className="w-16"
        classNames={{
            section: 'pointer-events-none',
        }}
        value={value}
        onChange={e => onChange(Number(e.currentTarget.value))}
    />
);
