import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Personalization, PersonalizationContextParsed } from '../../../types/Personalization';

type DefaultRecsLocalStateContextType = {
    defaultRecs: Partial<Personalization>[];
    onUpdateTable: (value: Record<string, Partial<Personalization>>) => void;
    onUpdateQuantity: (value: number, productID: string) => void;
    onDelete: (productID: string, productName: string) => void;
    onAdd: (
        rec: Partial<Omit<Personalization, 'vendor_id' | 'store_id' | 'rating' | 'user_rating'>>,
    ) => void;
    isDisabled: boolean;
};

const DefaultRecsLocalStateContext = createContext<DefaultRecsLocalStateContextType>({
    defaultRecs: [],
    onUpdateTable: (value: Record<string, Partial<Personalization>>) => {},
    onUpdateQuantity: (value: number, productID: string) => {},
    onDelete: (productID: string, productName: string) => {},
    onAdd: (
        rec: Partial<Omit<Personalization, 'vendor_id' | 'store_id' | 'rating' | 'user_rating'>>,
    ) => {},
    isDisabled: false,
});
DefaultRecsLocalStateContext.displayName = 'DefaultRecsLocalStateContext';

export const DefaultRecsLocalStateProvider = ({
    children,
    initialState,
}: {
    initialState: PersonalizationContextParsed<Personalization[]>;
    children: JSX.Element;
}) => {
    const initialStateContext = initialState?.context ?? [];
    const vendorID = initialState?.title?.replace('Default Recs ', '');
    const [defaultRecs, setDefaultRecs] = useState<Partial<Personalization>[]>(initialStateContext);

    useDeepCompareEffect(() => {
        setDefaultRecs(initialStateContext);
    }, [initialStateContext]);

    const onUpdateTable = (value: Record<string, Partial<Personalization>>) => {
        setDefaultRecs(Object.values(value));
    };
    const onUpdateQuantity = (value: number, productID: string) => {
        setDefaultRecs(prev =>
            prev.map(item => (item.product_id === productID ? { ...item, quantity: value } : item)),
        );
    };

    const onDelete = (productID: string, productName: string) => {
        setDefaultRecs(prev =>
            prev.filter(item => item.product_id !== productID || item.product_name !== productName),
        );
    };

    const onAdd = useCallback(
        (
            rec: Partial<
                Omit<Personalization, 'vendor_id' | 'store_id' | 'rating' | 'user_rating'>
            >,
        ) => {
            if (!vendorID) return;

            const newRec: Partial<Personalization> = {
                ...rec,
                vendor_id: vendorID,
            };
            setDefaultRecs(prev => [...prev, newRec]);
        },
        [vendorID],
    );

    const value = useMemo(
        () => ({
            defaultRecs,
            onUpdateQuantity,
            onDelete,
            onAdd,
            isDisabled: !vendorID,
            onUpdateTable,
        }),
        [defaultRecs, onAdd, vendorID],
    );
    return (
        <DefaultRecsLocalStateContext.Provider value={value}>
            {children}
        </DefaultRecsLocalStateContext.Provider>
    );
};

const useDefaultRecsContext = () => useContext(DefaultRecsLocalStateContext);

export { useDefaultRecsContext };
