import HeaderActionsWrapper from '@/responsive/HeaderActionsWrapper';
import { SpaceportCommonHeader } from '../utils/SpaceportCommonHeader';
import { UseCaseDropdown } from '../utils/UseCaseDropdown';
import { useConfigurationsQueryParams } from './useConfigurationsQueryParams';
import { NamespaceDropdown } from '../utils/NamespaceDropdown';

export const ConfigurationsHeader = () => {
    const { selectedUseCase, updateSelectedUseCase, selectedNamespace, updateSelectedNamespace } =
        useConfigurationsQueryParams();

    return (
        <SpaceportCommonHeader
            title="Configurations"
            description="View the personalized contextual configurations by module, as seen in BEES."
            actionsElement={
                <HeaderActionsWrapper>
                    <NamespaceDropdown
                        selectedNamespace={selectedNamespace}
                        updateSelectedNamespace={updateSelectedNamespace}
                    />
                    <UseCaseDropdown
                        selectedNamespace={selectedNamespace}
                        selectedModule={selectedUseCase}
                        updateSelectedModule={updateSelectedUseCase}
                    />
                </HeaderActionsWrapper>
            }
        />
    );
};
