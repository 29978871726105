import React, { useEffect, useState } from 'react';
import { Card } from 'andromeda/Card';
import { Text } from 'andromeda';
import { DateFormatter } from '@/utils/DateFormatter';
import { TextForLanguageContent } from './TextForLanguageContent';
import { LanguageDropdown } from './LanguageDropdown';
import { useUpdateContext } from './useUpdateContext';
import { useSelectedPersonalizationContext } from './useSelectedPersonalizationContext';
import { Button } from '../../andromeda/Button';

interface TextForLanguageProps {
    title: string;
}

export const TextForLanguage: React.FC<TextForLanguageProps> = ({ title: cardTitle }) => {
    const [selectedLanguage, setSelectedLanguage] = useState<string>();

    const { selectedContext, isLoading, error, data } = useSelectedPersonalizationContext({
        type: 'translations',
        language: selectedLanguage,
    });

    const [title, setTitle] = useState(selectedContext?.context?.title);
    const [description, setDescription] = useState(selectedContext?.context?.description);

    useEffect(() => {
        setTitle(selectedContext?.context?.title);
        setDescription(selectedContext?.context?.description);
    }, [selectedContext?.context?.title, selectedContext?.context?.description]);

    const { mutate, isPending: isLoadingUpdate } = useUpdateContext();

    const handleSave = () => {
        if (!selectedContext) return;
        mutate({
            id: selectedContext?.id,
            context: {
                ...selectedContext?.context,
                title,
                description,
            },
        });
    };

    return (
        <Card
            className="flex flex-col gap-2"
            innerClassName="grow flex flex-col justify-between"
            title={cardTitle}
            titleClassName="flex flex-col"
            tooltipElement={
                <div className="w-full">
                    {(selectedContext?.last_updated_by || selectedContext?.last_updated_at) && (
                        <Text type="12Reg">
                            Last updated{' '}
                            {selectedContext?.last_updated_by &&
                                `by ${selectedContext?.last_updated_by}`}
                            {selectedContext?.last_updated_at &&
                                ` on ${DateFormatter.formatDate(selectedContext?.last_updated_at)}`}
                        </Text>
                    )}
                </div>
            }
            loading={isLoading}
            error={error?.message}
            actionsElement={
                <LanguageDropdown
                    contexts={data?.personalization_contexts}
                    language={selectedLanguage}
                    onChange={setSelectedLanguage}
                />
            }
        >
            <TextForLanguageContent
                title={title}
                description={description}
                updateTitle={setTitle}
                updateDescription={setDescription}
            />
            <div className="mt-6 self-end">
                <Button
                    containerClassName="mt-auto ml-auto"
                    onClick={handleSave}
                    loading={isLoadingUpdate}
                >
                    Save changes
                </Button>
            </div>
        </Card>
    );
};
