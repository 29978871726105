import _ from 'lodash';
import cx from 'classnames';
import React, { PropsWithChildren } from 'react';
import { Input } from '@mantine/core';

const SectionTitle: React.FC<PropsWithChildren> = ({ children }) => (
    <h5 className="text-xs font-semibold uppercase text-navy-solid-70">{children}</h5>
);
interface TextForLanguageContentProps {
    title?: string;
    updateTitle: (title: string) => void;
    description?: string;
    updateDescription: (description: string) => void;
    className?: string;
}

export const TextForLanguageContent: React.FC<TextForLanguageContentProps> = ({
    title,
    description,
    updateTitle,
    updateDescription,
    className,
}) => (
    <div className={cx('space-y-6', className)}>
        <div className="space-y-2">
            <SectionTitle>Carousel Title</SectionTitle>
            <Input value={title ?? ''} onChange={e => updateTitle(e.currentTarget.value)} />
        </div>
        <div className="space-y-2">
            <SectionTitle>Carousel description</SectionTitle>
            <Input
                value={description ?? ''}
                onChange={e => updateDescription(e.currentTarget.value)}
            />
        </div>
    </div>
);
